@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1680C3;
  border-radius: 10px;
}

.App {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: linear-gradient(to bottom right, #2171C1, #1680C3);
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #1680C3;
}

.App-link {
  color: #61dafb;
}

.line{
  stroke: #000;
  stroke-width: 2;
  width: 100%;
}

.logo{
  margin-top: 70px;
}

.navbar{
  background-color: #2171C1;
}

.navbar-toggler{
  border-color: transparent !important;
}

.navbar-nav{
  cursor: pointer;
}

.nav-link{
  padding: 0.5rem 1.5rem !important;
}

.image{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

img{
  border-radius: 8px;
}

.btn-home-work{
  margin-top: 40px;
  font-size: 15px;
  border-radius: 50px;
  background-color: transparent;
  padding: 10px 25px;
  border: 1px solid white;
  color: white;
}

.btn-home-work:hover{
  margin-top: 40px;
  font-size: 15px;
  background-color: #1680C3;
  padding: 10px 25px;
  color: white;
}

.text-title{
  text-align: center;
  font-size: calc(10px + 2vmin);
  margin-top: 20px;
}

.text-color-white{
  color: #ffffff;
}

.text-color-blue{
  color: #1680C3;
}

a:hover{
  text-decoration:none !important;
}

.icon{
  float: left;
}

.copyright{
  float: right;
  color: white;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-fixed{
  background-color: #2171C1;
}

.btn-about-noactive{
  border-radius: 50px;
  background-color: white;
  color: #1680C3;
  border: 1px solid;
  padding: 10px 25px;
  margin: 15px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.btn-about{
  border-radius: 50px;
  background-color: #1681c3dc;
  color: white;
  border: 0;
  padding: 10px 25px;
  margin: 15px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.navbar-bar{
  text-align: right;
  padding: 15px 20px;
}

.btn-navbar-noactive{
  border-radius: 10px;
  background-color: transparent;
  border: 0;
  color: white;
  padding: 10px 25px;
  margin: 0 5px;
  cursor: pointer;
  text-align: center;
}

.btn-navbar{
  border-radius: 50px;
  background-color: #1680C3;
  border: 0;
  color: white;
  padding: 10px 25px;
  margin: 0 5px;
  cursor: pointer;
  text-align: center;
}

.btn-work-noactive{
  border-radius: 50px;
  background-color: white;
  color: #1680C3;
  border: 0px solid;
  padding: 10px 25px;
  margin: 15px 0;
  border-radius: 50px;
  cursor: pointer;
  width: 30%;
  text-align: center;
}

.btn-work{
  border-radius: 50px;
  background-color: #1681c3dc;
  color: white;
  border: 1px solid;
  padding: 10px 25px;
  margin: 5px 0;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
}

.col-button{
  margin-top: 20px;
}

.col-detailme{
  margin-top: 20px;
}

.top{
  position: absolute;
  top: 0;
  font-size: 16px;
}

.bottom{
  position: absolute;
  bottom: 0;
  font-size: 16px;
}

.footer{
  background-color: #1680C3;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ====================   Mobile  ==================== */
@media (max-width: 991px) {
  .icon{
    max-width: 500px;
    margin: 10px auto;
    text-align: center;
    display: block;
    float: none;
  }
  .copyright{
    max-width: 500px;
    margin: auto;
    text-align: center;
    display: block;
    float: none;
  }

  .btn-home-work{
    margin: 20px 0;
  }

  .btn-about, .btn-about-noactive{
    width: 7rem;
    text-align: center;
  }

  .col-button{
    margin-top: 0;
  }

  .navbar-bar{
    text-align: center;
    padding: 0px;
  }

  .btn-navbar-noactive{
    margin: 20px 0;
  }
  
  .btn-navbar{
    margin: 20px 0;
  }

  .image{
    padding: 10px 0;
  }
}
